export default {
  computed: {
    required() {
      if (!this.config.rules) {
        return false;
      }
      const some = this.config.rules.some(
        (item) => item.required && !item.disabled
      );
      return some;
    },
    rules() {
      if (!this.config.rules) {
        return [];
      }
      const ruleFilter = this.config.rules.filter((item) => !item.disabled);
      return ruleFilter;
    },
  },
  watch: {},
  methods: {},
};
