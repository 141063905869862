<template>
  <div class="picker">
    <van-field
      v-model="currentText"
      :label="elProps.label"
      :placeholder="elProps.placeholder"
      :readonly="elProps.readonly"
      :required="elProps.required"
      :rules="elProps.rules"
      :inputAlign="elProps.inputAlign"
      is-link
      @click="openPopup">
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <p class="picker-top">
          <span class="title">{{ elProps.label }}</span>
          <van-image
              width="18"
              height="18"
              :src="require('@/assets/images/close.png')"
              class="close-img"
              @click="pageData.show = false"
          />
        </p>
        <van-picker
          ref="picker"
          :show-toolbar="false"
          :title="elProps.label"
          :columns="options"
          :value-key="elProps.labelKey"
          item-height="40px"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        >
          <template #option="item">
            <p class="item-con">
              <span class="title">
                {{ item[elProps.labelKey] }}
              </span>
              <van-image
                  width="18"
                  height="18"
                  :src="require('@/assets/images/selected.png')"
                  class="selected-img"
              />
            </p>
          </template>
        </van-picker>
        <p class="picker-confirm">
          <van-button round block type="info" color="#15AEA2" class="btn" @click="confirm($event)">确定</van-button>
        </p>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { request } from '@/api/service'
import { findIndex, isArray } from 'lodash'
export default {
  name: 'HSelectAccount',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    },
    'config.options': {
      handler (newVal, oldVal) {
        this.options = newVal
      }
    }
  },
  data () {
    return {
      pageData:{
        show:false
      },
      val: null,
      options: []
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        show: true,
        readonly: true,
        disabled: false,
        options: [],
        valueKey:'value',
        labelKey:'label',
        clearable: true,
        blurEvent: null,
        changeEvent: null,
        placeholder: '请选择'
      }, this.config)
    },
    currentText:{
      get(){
        if(!(this.val&&this.options&&this.options.length>0)){
          return null
        }
        const textOption=this.options.filter(item=>{return item[this.elProps.valueKey] === this.val})
        if(textOption && textOption.length>0){
          return textOption[0][this.elProps.labelKey]
        }else{
          return null
        }
      },
      set(){

      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
      if (this.config.dictCode) {
        this.$set(this, 'options', this.pageContext.dictMap[this.config.dictCode])
      } else if (this.config.apiCode) {
        request({ url: this.config.apiCode, method: this.config.apiMethod || 'get', data: this.config.apiData })
          .then(res => {
            if (isArray(res)) {
              this.$set(this, 'options', res || [])
            }
          })
      }
    },
    handleEvent (eventType) {
      const event = this.config[eventType + 'Event']
      if (event) {
        if (typeof event=== 'string' || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this)
        } else if (event instanceof Function) {
          event(this)
        }
      }
    },
    openPopup(){
      this.pageData.show=true
      const index=findIndex(this.options,[this.elProps.valueKey, this.val])
      this.$nextTick(()=>{
        this.$refs.picker.setColumnIndex(0,index)
      })
    },
    handleConfirm(data){
      this.pageData.show=false
      if(!data){
        return false
      }
      this.val=data[this.elProps.valueKey]
      this.config.val = this.val
      this.$emit('input', this.val)
      this.handleEvent('confirm')
    },
    handleCancel(){
      this.pageData.show=false
    },
    confirm (e) {
      e.stopPropagation()
      e.preventDefault()
      this.handleConfirm(this.$refs.picker.getValues()[0])
    }
  }
}
</script>
<style lang="scss">
.picker {
  background: #fff !important;
  p {
    margin: 0;
    padding: 0;
  }
  .picker-top {
    font-size: 16px;
    height: 58px;
    line-height: 58px;
    width: 6.7rem;
    margin: 0 auto;
    border-bottom: 1px solid #DFDFDF;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
    }
    .close-img {
      position: relative;
      top: 20px;
    }
  }
  .item-con {
    width: 6rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: #747474;
    letter-spacing: 0.79px;
    .selected-img {
      display: none;
    }
  }
  .van-picker-column__item.van-picker-column__item--selected .item-con {
    color: #37B8AD;
    .selected-img {
      display: block;
    }
  }
  .picker-confirm {
    height: 1.4rem;
    line-height: 1.4rem;
    width: 6.7rem;
    margin: 0 auto;
    border-top: 1px solid #DFDFDF;
    .btn {
      width: 2.98rem;
      margin: 0 auto;
      position: relative;
      top: 0.264rem;
    }
  }
  .van-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .van-popup--bottom.van-popup--round {
    border-radius: 16px 16px 0 0 !important;
    background: #fff !important;
  }
}
</style>
