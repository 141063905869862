<template>
  <div class="date-select">
    <van-field
      v-model="currentDateFormat"
      :label="elProps.label"
      :placeholder="elProps.placeholder"
      :readonly="elProps.readonly"
      :disabled="elProps.disabled"
      :required="required"
      :rules="rules"
      :inputAlign="elProps.inputAlign"
      :labelWidth="elProps.labelWidth"
      is-link
      @click="openPopup">
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          :type="elProps.type"
          :title="elProps.label"
          :min-date="elProps.minDate"
          :max-date="elProps.maxDate"
          @change="handleEvent('popupChange')"
          @confirm="handleEvent('confirm')"
          @cancel="handleEvent('cancel')"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import mixins from './mixins'
export default {
  name: 'HDatePicker',
  mixins:[mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    }
  },
  data () {
    return {
      pageData:{
        show:false
      },
      val: null,
      currentDate:new Date()
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        label:null,
        show: true,
        readonly: true,
        disabled: false,
        type: 'date', // datetime year-month  month-day time
        format: 'YYYY-MM-DD',
        placeholder: '请选择',
        minDate: dayjs().subtract(20, 'year').toDate(),
        maxDate: dayjs().add(20, 'year').toDate(),
        changeEvent: null
      }, this.config)
    },
    currentDateFormat:{
      get(){
        if(this.val){
          return dayjs(this.val).format(this.elProps.format)
        }
        return null
      },
      set(val){
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
      if(this.val){
        this.currentDate= dayjs(this.val).toDate()
      }
    },
    handleEvent (eventType) {
      if (eventType === 'confirm') {
        this.pageData.show = false
        if (this.currentDate) {
          this.val = this.currentDate.getTime()
        }
        this.config.val = this.val
        this.$emit('input', this.val)
      } else if(eventType === 'cancel'){
        this.pageData.show = false
      }
      const event = this.config[eventType + 'Event']
      if (event) {
        if (typeof event=== 'string' || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this)
        } else if (event instanceof Function) {
          event(this)
        }
      }
    },
    openPopup(){
      if(this.elProps.disabled){
        return false
      }
      this.pageData.show=true
      if(this.val){
        this.currentDate=dayjs(this.val).toDate()
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.date-select ::v-deep {
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}
</style>
