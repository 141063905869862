<template>
  <van-field
    v-if="elProps.show"
    v-model="val"
    v-bind="{ ...$attrs, ...elProps }"
    @input="handleEvent('input')"
    @blur="handleEvent('blur')"
    @change="handleEvent('change')"
  >
    <template #right-icon>
        <span v-if="config.append">{{ config.append }}</span><slot name="right-icon"></slot>
    </template>
    <template #button>
      <slot name="button"></slot>
    </template>
  </van-field>
</template>
<script>
export default {
  name: 'HInputAccount',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    }
  },
  data () {
    return {
      val: null
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        label:null,
        append: null,
        show: true,
        size: null,
        readonly: false,
        disabled: false,
        clearable: true,
        blurEvent: null,
        changeEvent: null,
        placeholder: '请输入'
      }, this.config)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
    },
    handleEvent (eventType) {
      if (eventType === 'input') {
        if (this.val && this.config.toUpperCase) {
          this.val = this.val.toUpperCase()
        }
        this.config.val = this.val
        this.$emit('input', this.val)
      } else {
        const event = this.config[eventType + 'Event']
        if (event) {
          if (typeof event=== 'string' || event instanceof String) {
            // eslint-disable-next-line no-new-func
            new Function(`return function(ctx) { ${event} }`)()(this)
          } else if (event instanceof Function) {
            event(this)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
