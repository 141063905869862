<template>
  <div class="tag-box">
      <span class="tag" v-for="(item, ind) in list" :key="ind" @click="updateRecord(item.labelName)">
        {{ item.labelName }}&nbsp; <span class="close" @click="close(item)">x</span>
    </span>
    <span class="tag" @click="addTag">
        <span>+</span>
        自定义标签
    </span>
    <van-action-sheet v-model="show" @click="clickOverlay($event)" title="自定义标签">
        <van-field
            placeholder="请输入自定义标签"
            v-model="labelName"
            maxlength="10"
            class="van-tag"
            style="text-align: left;">
        </van-field>
        <div class="line"></div>
        <div class="tip-con">自定义标签最多10个字</div>
        <div class="line" style="margin-top: 3rem; margin-bottom: 14px;"></div>
        <div class="tag-btn">
            <van-button round block type="info" @click.native="submitForm" color="#15AEA2" style="width: 3rem; display: inline-block; margin-bottom: 14px;">确定</van-button>
        </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { Dialog, Toast, Notify } from 'vant'
import { request } from "@/api/service";
export default {
  name: 'HTag',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    list: {
        type: Array
    },
    id: {
        type: String
    },
    maxNum: {
        type: Number
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    }
  },
  data () {
    return {
      val: null,
      show: false,
      labelName: null,
      isClickMask: false
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
      close (item) {
            Dialog.confirm({
                title: '提示',
                message: '确定要删除吗',
            })
            .then(() => {
                request({
                    url: `/afis-engine/label/cfgLabel/changeStatus`,
                    method: "post",
                    data: {
                        id: item.id,
                        status: '0'
                    }
                }).then(res => {
                    Toast.success('删除成功')
                    this.$emit('close')
                })
            })
            .catch(() => {
                // on cancel
            });
      },
      addTag () {
          this.labelName = null
          if (this.list.length > this.maxNum - 1) {
              Dialog.alert({
                title: '提示',
                message: `标签自行添加不允许超过${this.maxNum}个`,
                }).then(() => {
              });
              return
          }
          this.show = true
      },
      submitForm () {
            if (!this.labelName) {
              Notify({ type: 'danger', message: '请输入标签内容' })
              return
            }
            request({
                url: `/afis-engine/label/cfgLabel/save`,
                method: "post",
                data: {
                    id: this.id,
                    labelName: this.labelName,
                    labelType: 'R_SA',
                    useType: '1'
                }
            }).then(res => {
                Toast.success('添加成功')
                this.$emit('getTagList')
                this.show = false
            })
      },
      updateRecord (labelName) {
          this.$emit('updateRecord', labelName)
      },
      clickOverlay (e) {
        e.stopPropagation()
        e.preventDefault()
      }
  }
}
</script>
<style lang="scss" scoped>
    .tag-box ::v-deep {
        .tag {
            display: inline-block;
            font-size: 12px;
            padding: 2px 6px;
            background: #FFFFFF;
            border: 1px solid #D8D8D8;
            border-radius: 5px;
            margin-right: 10px;
            color: #6C6C6C;
            margin-top: 4px;
            .close {
                display: inline-block;
                width: 16px;
                height: 16px;
                background: #D9D9D9;
                text-align: center;
                line-height: 16px;
                border-radius: 50%;
                color: #fff;
            }
        }
        .van-tag {
            margin-top: 20px;
            .van-field__control {
                text-align: left;
            }
        }
        .line {
            margin: 0 auto;
            border-bottom: 1px solid #DDDDDD;
            margin-bottom: 10px;
            width: 90%;
        }
        .tip-con {
            color: #747474;
            font-size: 12px;
            width: 90%;
            margin: 0 auto;
        }
        .tag-btn {
            text-align: center;
        }
        .van-action-sheet {
          background: #fff !important;
        }
        .van-overlay {
          background: rgba(0, 0, 0, .7) !important;
        }
    }
</style>
